.bulb {
	width: 420px;
	height: 520px;
	position: relative;
}

.bulb span {
  position: absolute;
  inset: 0;
}

.bulb span::before {
  content: "";
  position: absolute;
  width: 420px;
  height: 520px;
  background: url("../../../../assets/images/lamp_off.png");
  background-size: cover;
  background-position: center;
  transform: scale(1);
  animation: animate 2.5s linear infinite;
}

@keyframes animate {
  0%,
  100% {
    background: url("../../../../assets/images/lamp_off.png");
  }
  50% {
    background: url("../../../../assets/images/lamp_on.png");
  }
}