@font-face {
 font-family: "Aventa";
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-Black.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-style: italic;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-BlackItalic.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 600;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-Bold.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 600;
 font-style: italic;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-BoldItalic.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 700;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-ExtraBold.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 700;
 font-style: italic;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 300;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-ExtraLight.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 300;
 font-style: italic;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-style: italic;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-Italic.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 400;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-Light.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 400;
 font-style: italic;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-LightItalic.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 500;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-Medium.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 font-weight: 500;
 font-style: italic;
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-MediumItalic.ttf") format("truetype");
}
/* @font-face {
 font-family: "Aventa";
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-Regular.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-Thin.ttf") format("truetype");
}
@font-face {
 font-family: "Aventa";
 src: local("Aventa"), url("./assets/fonts/Aventa/Aventa-ThinItalic.ttf") format("truetype");
} */