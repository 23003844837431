.bottom-container {
  min-height: 250px;
  cursor:none
  /* position: relative; */
}

#circular-text {
  fill: currentColor;
  height: auto;
  max-width: 66vmin;
  transform-origin: center;
  width: 100%;
  transform-box: fill-box;
  transform-origin: 50% 50%;
  animation: rotate 6s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}