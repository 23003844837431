img {
  height: auto;
  cursor: pointer;
}

img.active {
  width: 648px;
  transition: all 2s ease-in-out;
}

img.prev-next {
  width: 30%;
  filter: blur(3px);
  -webkit-filter: blur(3px);
}

@media screen and (max-width: 1024px) {
img.active {
  width: 400px;
}
}
@media screen and (max-width: 768px) {
  img.active {
    width: 400px;
  }
}

@media screen and (max-width: 600px) {
  img.active {
    width: 250px;
  }
}